<template>
  <v-container fluid>
    <v-row v-if="processoDivulgacao" justify="center">
      <v-col cols="auto">
        <v-card width="1000">
          <v-card-title class="justify-center titulo">
            {{ processoDivulgacao.nome }}
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <div
                class="mb-10"
            >
              <span v-html="processoDivulgacao.divulgacaoTexto"></span>
            </div>
          </v-card-text>
          <v-card-actions
              class="justify-space-around"
          >
            <v-btn v-if="processoDivulgacao.abertoParaInscricao" color="accent" @click.prevent="irParaInscricao"
            >Ir para Inscrição
            </v-btn>
            <v-btn v-if="processoDivulgacao.abertoParaRecurso" color="accent" @click.prevent="irParaRecurso"
            >Ir para Recurso
            </v-btn>
            <v-btn v-if="processoDivulgacao.abertoCartaoConfirmacao" color="accent" @click.prevent="irParaCartaoConfirmacao"
            >Ir para Cartão de Confirmação
            </v-btn>
            <v-btn v-if="documentacaoPendente != null && processoDivulgacao.abertoParaDocumentacaoPendente" color="accent" @click.prevent="irParaDocumentacaoPendente"
            >Ir para Documentação Pendente
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import processoService from "@/services/processo.service";
import cartaoConfirmacaoService from "@/services/cartaoConfirmacao.service";
import documentacaoPendenteService from "@/services/documentacaoPendente.service";
import {mapActions} from "vuex";

export default {
  name: "ProcessoDivulgacao",

  data() {
    return {
      processoDivulgacao: null,
      documentacaoPendente: null
    };
  },

  async created() {
    let loader = this.$loading.show();

    await processoService
        .recuperarProcessoDivulgacao(this.$route.params.oid)
        .then(response => {
          this.processoDivulgacao = response.data;
        })
        .catch(() => {
          this.$router.push({name: "404"}).catch(() => {
          });
        });

    await this.recuperarDocumentacaoPendente();

    loader.hide();
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    irParaInscricao() {
      this.$router.push({
        name: "Inscricao",
        params: {oid: this.$route.params.oid}
      });
    },

    irParaRecurso() {
      this.$router.push({
        name: "Recurso",
        params: {oid: this.$route.params.oid}
      });
    },

    irParaDocumentacaoPendente() {
      this.$router.push({
        name: "DocumentacaoPendente",
        params: {oid: this.$route.params.oid}
      });
    },

    async irParaCartaoConfirmacao() {
      await cartaoConfirmacaoService.recuperarCartaoConfirmacao(this.$route.params.oid)
          .then(() => {
            this.$router.push({
              name: "CartaoConfirmacao",
              params: {oid: this.$route.params.oid}
            });
          }).catch(error => {
            let mensagem = "Erro ao recuperar cartão de confirmação";
            let tipo = "error";
            if (error.response.status === 404) {
              mensagem = "Você não se inscreveu neste processo";
            }
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
              tipo = "info";
            }
            if (error.response.status === 403) {
              mensagem = "Efetue o login para prosseguir com sua solicitação";
              tipo = "info";
            }

            this.exibirAviso({
              mensagem: mensagem,
              tipo: tipo
            });
          });
    },

    async recuperarDocumentacaoPendente() {
      await documentacaoPendenteService
          .recuperarDocumentacaoPendentePorUsuarioLogado(this.$route.params.oid)
          .then(async response => {
            this.documentacaoPendente = response.data;
          })
          .catch(() => {
          });
    },
  }
};
</script>
